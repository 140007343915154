.navigation {
    width: 250px; /*for demo, it was 10%*/
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    left: 10px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: bold;
    height: 0px;
    top: 10px;
    z-index: 103;
}

.head span:hover {
    text-decoration: none !important;
    cursor: initial !important;
}

.navigation nav {
    margin-left: 0;
}
.navigation a {
    color: var(--navigation-text-color);
}
.navigation span {
    color: var(--navigation-text-color);
}
.navigation span:hover {
    color: var(--navigation-text-color);
    cursor: pointer;
    /*  color:#3D7D97;*/
    text-decoration: underline;
    text-decoration-thickness: 2px;
}
.navigation a:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
}
.active {
    text-decoration: underline;
}
inactive {
    text-decoration: none;
}

.navActive {
    display: block;
    transition: 0.5s;
}
.navInactive {
    display: none;
    transition: 0.5s;
}

.button {
    position: absolute;
    bottom: 65px;
    background-color: transparent;
    border: none;
    display: none;
}

.burger1,
.burger2,
.burger3 {
    width: 35px;
    height: 5px;
    background-color: var(--font-color); /*333 for light mode*/
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 23px;
}

/* Rotate first bar */
.change div:nth-child(1) {
    transform: translate(0, 11px) rotate(-45deg);
}

/* Fade out the second bar */
.change div:nth-child(2) {
    opacity: 0;
}

/* Rotate last bar */
.change div:nth-child(3) {
    transform: translate(0, -11px) rotate(45deg);
}

@media only screen and (max-width: 1365px) {
    .backdrop {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.555);
        z-index: 101;
        display: none;
        transition: 0.5s ease;
        top: 0;
    }
    .navigation nav {
        margin-left: -20px;
    }
    .width {
        transform: translateX(0) !important;
    }
    .mobile-tree {
        margin-left: 0 !important;
        transform: translateX(-100vw);
        transition: 0.3s;
        padding: 20px;
        background-color: var(--header-bottom-background);
        width: 100%;
        height: 2000px;
        position: absolute;
        margin-top: -30px;
        overflow: scroll;
    }
    .navigation nav {
        position: relative;
        z-index: 103;
    }

    .button {
        display: block;
    }

    .head {
        display: none;
    }
    .treediv {
        display: none;
    }
}
.treediv {
    margin-left: 10px;
}

@media only screen and (max-width: 760px) {
    .button {
        bottom: 56px;
    }
    .navigation nav {
        margin-left: -20px;
    }

    .width {
        transform: translateX(-100vw);
    }

    .mobile-tree {
        margin-left: 0;
        transition: 0.4s ease;
        padding: 20px;
        background-color: var(--header-bottom-background);
        height: 2000px;
        position: absolute;
        margin-top: -30px;
        overflow: scroll;
    }
    .burger1,
    .burger2,
    .burger3 {
        width: 27px;
        height: 3px;
        background-color: var(--font-color); /*333 for light mode*/
        margin: 4px 0;
        transition: 0.4s;
        border-radius: 23px;
    }
    .change div:nth-child(1) {
        transform: translate(0, 7px) rotate(-45deg);
    }

    /* Fade out the second bar */
    .change div:nth-child(2) {
        opacity: 0;
    }

    /* Rotate last bar */
    .change div:nth-child(3) {
        transform: translate(0, -7px) rotate(45deg);
    }
}

.head {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.treediv {
    display: none;
}
.mobile-tree {
    margin-left: 20px;
}
