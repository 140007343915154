.sidenav {
  height: 100%;
  position: fixed;
  z-index: 105;
  top: 0;
  right: 0;
  background-color: #373737;
  transition: 0.5s ease;
  overflow-x: hidden;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Arial", Arial, Tahoma, sans-serif;
  font-size: 1rem;
  white-space: nowrap;
}

.active {
  color: white;
}
.inactive {
  color: #a6a6a6;
}

.button {
  padding: 5px;
  float: right;
  margin-right: 20px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 3px;
  border-style: none;
  margin-bottom: 42px;
}

.button:before {
  content: "";
  position: absolute;
  top: 21px;
  right: 30px;
  border: 1px solid #a1a1a169;
  width: 10px;
  height: 12px;
  border-top: 2px solid #807f7fc0;
  border-bottom: 2px solid #807f7fc0;
}

.sidenav nav ul li {
  height: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.04);
  display: flex;
  align-items: center;
}
.sidenav nav ul li:nth-child(3) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}
.sidenav nav ul li span {
  margin-left: 15px;
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.555);
  z-index: 104;
  display: none;
  transition: 0.5s ease;
}

.dark-toggle > div {
  left: 0;
  margin-left: 15px;
  margin-right: 0;
  margin-top: 7px;
}

.dark-toggle > div > label > input:checked + div:before {
  transform: translateX(26px);
}

.dark-toggle > div > label > input:checked + div {
  background-color: cornflowerblue;
}
