.button {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 18px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 20px;
  border-radius: 0.25rem;
  white-space: nowrap;
  position: relative;
  z-index: 0;
}
.button:hover {
  background: rgb(255, 255, 255);
  transition: color 0.3s ease 0s, background 0s ease 0.3s;
}

.button:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.button:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.178);
  transition-duration: 0.3s;
  z-index: -1;
  inset: 0px auto auto 0px;
  width: 0px;
  height: 100%;
  opacity: 1;
}
