.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.555);
  z-index: 300;
  display: none;
  transition: 0.5s ease;
  top:0;
}

.wrapper{
  color: black;
  position: absolute;
  background-color: #F5F5F5 ;
  width: 800px;
  height: 500px;
  left:50%;
  margin-left:-400px;
  top:45%;
  margin-top:-250px;
  z-index:300;
  border-radius: 6px;

}


.content{
  padding: 25px 0;
}

.text{
    position: absolute;
    width: auto;
    height: calc(100% - 120px);
    overflow-y: auto;
    margin: 10px 30px 30px 30px;
    padding: 0px 20px 0px 0px;
  margin-left:25px;
  margin-right:20px;
  font-size:17px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-top: -2px;
}

.title span{
  font-size: 25px;
  font-weight:bold;
  padding: 0px 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.title {
  border-bottom: 1px solid #E0E0E0;
  height:40px;
}

.close{
  position:relative;
  float:right;
  margin-right:20px;
  cursor:pointer;
  font-size:2rem;
  color: gray ;
  font-weight:bold;
  bottom:6px;
}

.close:after{
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}


@media only screen and (max-width: 850px) {

.wrapper{
  position: absolute;
  background-color: #F5F5F5 ;
  width: 600px;
  height: 500px;
  left:50%;
  margin-left:-300px;
  top:45%;
  margin-top:-250px;
  z-index:300;
  border-radius: 6px;
}

}

@media only screen and (max-width: 650px) {

.wrapper{
  position: absolute;
  background-color: #F5F5F5 ;
  width: 500px;
  height: 500px;
  left:50%;
  margin-left:-250px;
  top:45%;
  margin-top:-250px;
  z-index:300;
  border-radius: 6px;
}
}

@media only screen and (max-width: 550px) {

.wrapper{
  position: absolute;
  background-color: #F5F5F5 ;
  width: 400px;
  height: 600px;
  left:50%;
  margin-left:-200px;
  top:45%;
  margin-top:-250px;
  z-index:300;
  border-radius: 6px;
}
}

@media only screen and (max-width: 450px) {

.wrapper{
  position: absolute;
  background-color: #F5F5F5 ;
  width: 360px;
  height: 600px;
  left:50%;
  margin-left:-180px;
  top:45%;
  margin-top:-250px;
  z-index:300;
  border-radius: 6px;
}
}

@media only screen and (max-width: 359px) {

.wrapper{
  position: absolute;
  background-color: #F5F5F5 ;
  width: 300px;
  height: 600px;
  left:50%;
  margin-left:-150px;
  top:45%;
  margin-top:-250px;
  z-index:300;
  border-radius: 6px;
}
}
