.bg-container p {
  font-size: 1.2rem;
  color: #444444 !important;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  line-height: 1.2rem;
  font-size: 1rem;

}

.bg-container {
  background-color: #fff !important;
  height: 100vh;
}

.text-container p:nth-child(2) {
  margin-top: 18px;
  line-height: 1.6rem;
  margin-bottom: 20px;
}

@media screen and (min-width: 1162px) {
  .octopus-wrapper {
    display: none;
  }
  .octopus {
    display: none;
  }

  .bg-container p {
    font-size: 1.2rem;
    color: #444444;
  }

  .bg-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg {
    height: 100vh; /*to make it fit on other screen sizes, not tested enough on 1920x1080*/
    max-width: 100vw;
    max-height: 100vh;
  }

  .text-container {
    position: absolute;
    max-width: 50vw;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: 50px;
    right: 0;
    margin-bottom: 120px;
  }

  .text-container p:nth-child(2) {
    margin-top: 18px;
    line-height: 1.6rem;
    margin-bottom: 20px;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 80%;
  }
  .buttons a:nth-child(3) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1280px) {
  .buttons {
    width: 100%;
    margin-bottom: -20px;
  }
}

@media screen and (max-width: 1162px) {
  /*1167px*/
  .octopus {
    width: 100%;
  }
  .bg {
    display: none;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 150px;
    /* position: absolute;
    margin-top: -73vh; */
    width: 90%;
  }
  .buttons {
    height: 100px;
  }
  .buttons a:nth-child(1) {
    margin-right: 20px;
  }
  .buttons a:nth-child(3) span {
    margin-top: 15px;
    margin-right: 0;
  }

  .text-container p {
    text-align: center;
  }
}

@media screen and (min-width: 537px) {
  .buttons a {
    margin-right: 20px;
  }
  .buttons a:nth-child(3) {
    margin-right: -20px;
  }
}

@media screen and (max-width: 381px) {
  .buttons {
    display: flex;
    flex-direction: column;
  }

  .bg-container p {
    font-size: 1.2rem;
    color: #444444;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    line-height: 1.2rem;
    font-size: 1.1rem;
  }

  .buttons a {
    margin-bottom: 20px;
  }
  .buttons a:nth-child(1) {
    margin-right: 0;
  }
  .buttons a:nth-child(3) span {
    margin-top: 0;
    margin-right: 0;
  }

  .text-container p:nth-child(2) {
    margin-top: 18px;
    line-height: 1.2rem;
    margin-bottom: 20px;
  }
}
