.unset *:not(.latex *:not(.base), iframe){
    all:revert;
    line-height:2rem;
    margin:0;
}

.unset2 *:not(.latex *:not(.base)){
    all:revert;
    margin:0;
}



