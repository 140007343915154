.card {
  padding: 10px 0;
  width: 31.333%;
  margin: 0 1% 40px;
  box-sizing: border-box;
  position: relative;
  float: left;
  height: 220px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 24px;
  width: calc(100% - 12px);
  height: calc(100% - 1px);
  border-right: 1px solid var(--borders);
}

.card:nth-child(3n):after {
  display: none;
}

.card span {
  margin-top: 15px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1.3rem;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--card-color);
  text-align: center;
}

@media only screen and (max-width: 760px) {
  .card {
    float: none;
  }
  .card:after {
    display: none;
  }
  .card span {
    font-size: 1.2rem;
  }
}
