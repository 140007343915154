@import url("https://fonts.googleapis.com/css2?family=Literata:opsz@7..72&display=swap");
/*.unset :not(.new.latex) {
  all: revert;
  font-size: 13pt;
  line-height: 2rem;
  margin: 0;
}*/

.wrapper {
  margin-top: 0px;
  font-size: 13pt;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 2.8rem;
  font-family: "Literata", serif;
}

.inactive {
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.active {
  max-height: 10000px;
  transition: max-height 1s ease-in-out;
}
.text {
  overflow: hidden;
}

.tooltip {
  color: red !important;
}

.new {
  display: none;
}
.image {
  max-width: 100% !important;
  display: block !important;
}

.title {
  font-weight: bold;
  border-bottom: 2px solid var(--accordion-border-bottom);
  cursor: pointer;
  color: var(--font-color-title);
}
